import React, { useEffect } from "react";

import styles from './RecipeHero.module.css';
import Recipe from "../Recipe/Recipe";
import recipeData from "../../static/recipes.js";

// Shuffle recipes so they are in a random order
const shuffledRecipes1 = recipeData.sort(() => 0.5 - Math.random());
const shuffledRecipes2 = recipeData.sort(() => 0.5 - Math.random());

// Make the list of recipes longer so it doesn't run out of recipes to show
const extendedRecipes1 = [...shuffledRecipes1, ...shuffledRecipes1, ...shuffledRecipes1];
const extendedRecipes2 = [...shuffledRecipes2, ...shuffledRecipes2, ...shuffledRecipes2];

const RecipeHero = () => {
    return (
        <div className={styles.section3}>
            <div className={styles.topTextFrame}>
                    <div>
                        <h2>Delicious Curated Recipes</h2>
                        <h3>Made by chefs not AI</h3>
                    </div>
            </div>
            <div className={styles.bottomFrame}>
                <div className={styles.scrollingRecipes}>
                    <div className={styles.recipeTrack}>
                        {extendedRecipes1.map((recipe, index) => ( 
                            <Recipe
                                key={index}
                                imageUrl={recipe.imageUrl}
                                recipeName={recipe.recipeName}
                                author={recipe.author}
                                ingredientAmount={recipe.ingredientAmount}
                            />
                        ))}
                    </div>
                </div>
                <div className={styles.scrollingRecipes}>
                    <div className={styles.recipeTrack2}>
                        {extendedRecipes2.map((recipe, index) => ( 
                            <Recipe
                                key={index}
                                imageUrl={recipe.imageUrl}
                                recipeName={recipe.recipeName}
                                author={recipe.author}
                                ingredientAmount={recipe.ingredientAmount}
                            />
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
};


export default RecipeHero;
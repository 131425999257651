import React from 'react';
import ConnectIcon from './icons/ConnectIcon';
import BusinessesIcon from './icons/BusinessesIcon';
import FamiliesIcon from './icons/FamiliesIcon';
import KnowledgeIcon from './icons/KnowledgeIcon';
import ArtsIcon from './icons/ArtsIcon';
import HubsIcon from './icons/HubsIcon';
import WellbeingIcon from './icons/WellbeingIcon';
import RecreationIcon from './icons/RecreationIcon';
import ServicesIcon from './icons/ServicesIcon';
import SpacesIcon from './icons/SpacesIcon';
import CalendarIcon from './icons/CalendarIcon';
import LocationIcon from './icons/LocationIcon';
import AdminIcon from './icons/AdminIcon';
import PersonIcon from './icons/PersonIcon';
import PrintIcon from './icons/PrintIcon';
import LeftIcon from './icons/LeftIcon';
import RightIcon from './icons/RightIcon';
import ZoomInIcon from './icons/ZoomInIcon';
import ZoomOutIcon from './icons/ZoomOutIcon';
import TimeIcon from './icons/TimeIcon';
import FlagIcon from './icons/FlagIcon';
import EditIcon from './icons/EditIcon';
import FoldedMapIcon from './icons/FoldedMapIcon';
import DeviderLineIcon from './icons/DeviderLineIcon';
import ExportIcon from './icons/ExportIcon';
import LogoutIcon from './icons/LogoutIcon';
import FridgeIcon from './icons/FridgeIcon';

const Icon = ({ name, ...props }) => {
  const icons = {
    ConnectIcon,
    BusinessesIcon,
    FamiliesIcon,
    ExportIcon,
    KnowledgeIcon,
    ArtsIcon,
    HubsIcon,
    EditIcon,
    WellbeingIcon,
    RecreationIcon,
    ServicesIcon,
    SpacesIcon,
    AdminIcon,
    LocationIcon,
    CalendarIcon,
    PersonIcon,
    PrintIcon,
    LeftIcon,
    TimeIcon,
    FlagIcon,
    FoldedMapIcon,
    RightIcon,
    ZoomInIcon,
    ZoomOutIcon,
    DeviderLineIcon,
    LogoutIcon,
    FridgeIcon
  };

  const IconComponent = icons[name];
  if (!IconComponent) return null;

  return <IconComponent {...props} />;
};

export default Icon;

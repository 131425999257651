import React from 'react';
import Showcase from '../../static/Showcase.png';
import './Hero.css';
import HeroButton from '../HeroButton/HeroButton';

const Hero = () => (
  <div className="hero">
    <div className="showcase-container">
      <img 
        className="showcase-image"
        src={Showcase}
        alt="App Showcase"
      />
    </div>
    <div className="hero-msg">
        <h1>Recipes Using <br />Your Ingredients</h1>
        <HeroButton />
    </div>
  </div>
);

export default Hero;
export const recipeData = [
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2016/01/Salmon-Alfredo_7.jpg",
      recipeName: "Alfredo Salmon",
      author: "Recipe Tin Eats",
      ingredientAmount: "7/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2014/11/Aloo-Paratha-3.jpg",
      recipeName: "Aloo Paratha",
      author: "Recipe Tin Eats", 
      ingredientAmount: "9/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2018/10/Coconut-Noodle-Soup_3.jpg",
      recipeName: "Thai Soup",
      author: "Recipe Tin Eats",
      ingredientAmount: "8/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2014/07/Anzac-Biscuits_7.jpg",
      recipeName: "Anzac Biscuits",
      author: "Recipe Tin Eats",
      ingredientAmount: "7/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2016/01/Apple-Cider-Glazed-Pork-Tenderloin_6.jpg",
      recipeName: "Pork Tenderloin",
      author: "Recipe Tin Eats",
      ingredientAmount: "9/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2014/06/DSC_00471.jpg",
      recipeName: "French Toast",
      author: "Recipe Tin Eats",
      ingredientAmount: "7/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2016/05/Apple-Crumble_1a-1.jpg",
      recipeName: "Apple Crumble",
      author: "Recipe Tin Eats",
      ingredientAmount: "8/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2019/05/Apple-Crumble-Bars_9.jpg",
      recipeName: "Crumble Bars",
      author: "Recipe Tin Eats",
      ingredientAmount: "6/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2018/10/Apple-Salad-with-Candied-Walnuts-Cranberries_3-2.jpg",
      recipeName: "Apple Salad",
      author: "Recipe Tin Eats",
      ingredientAmount: "8/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2021/04/Apple-Sauce_0.jpg",
      recipeName: "Apple Sauce",
      author: "Recipe Tin Eats",
      ingredientAmount: "4/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2017/05/Asian-beef-Bowls.jpg",
      recipeName: "Beef Bowls",
      author: "Recipe Tin Eats",
      ingredientAmount: "7/10",
    },
    {
      imageUrl: "https://www.recipetineats.com/wp-content/uploads/2016/06/Asian-Marinated-Chicken_Hero.jpg",
      recipeName: "Thai Marinade",
      author: "Recipe Tin Eats",
      ingredientAmount: "9/10",
    },
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2015/12/Homemade-Hot-Chocolate-recipe-with-two-ingredients.jpg",
			recipeName: "Hot Chocolate",
			author: "Well Plated",
			ingredientAmount: "4/10",
		},
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2018/01/Golden-Milk-for-Sleep.jpg",
			recipeName: "Golden Milk",
			author: "Well Plated",
			ingredientAmount: "5/10",
		},
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2023/01/Best-Instant-Pot-Salmon.jpg",
			recipeName: "Instant Salmon",
			author: "Well Plated",
			ingredientAmount: "2/10",
		},
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2021/02/Best-Healthy-Chocolate-Chip-Cookies.jpg",
			recipeName: "Choc Cookies",
			author: "Well Plated",
			ingredientAmount: "8/10",
		},
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2018/02/Healthy-Garlic-Shrimp-Pasta-with-Lemon.jpg",
			recipeName: "Shrimp Pasta",
			author: "Well Plated",
			ingredientAmount: "9/10",
		},
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2020/02/Best-Chocolate-Hummus.jpg",
			recipeName: "Chocolate Dip",
			author: "Well Plated",
			ingredientAmount: "5/10",
		},
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2020/12/Peanut-Butter-Banana-Smoothie-recipe.jpg",
			recipeName: "Banana Shake",
			author: "Well Plated",
			ingredientAmount: "6/10",
		},
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2023/01/Best-Oatmeal-Cookies.jpg",
			recipeName: "Oatmeal Cookies",
			author: "Well Plated",
			ingredientAmount: "10/10",
		},
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2023/02/Best-Air-Fryer-Potato-Wedges.jpg",
			recipeName: "Potato Wedges",
			author: "Well Plated",
			ingredientAmount: "3/10",
		},
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2021/12/Best-Oven-Roasted-Vegetables-Recipe.jpg",
			recipeName: "Roast Veges",
			author: "Well Plated",
			ingredientAmount: "8/10",
		},
		{
			imageUrl: "https://www.wellplated.com/wp-content/uploads/2016/03/Black-Bean-Vegan-Burger-Recipe.jpg",
			recipeName: "Vegan Burger",
			author: "Well Plated",
			ingredientAmount: "10/10",
		}
  ];

export default recipeData;
import React from 'react';
import styles from './Contact.module.css'; // Import the styles
import Footer from '../../components/Footer/Footer';

const Contact = () => (
  <div> 
    <div className={styles.contact}>
        <h2>Contact Us</h2>
        <p>Have questions or suggestions? We'd love to hear from you!</p>
        <a href="mailto:marcel@pantrypal.com.au" className="email-link">
          marcel@pantrypal.com.au
        </a>
    </div>
    <Footer />
  </div>

);

export default Contact;
import React from 'react';
import styles from './Footer.module.css'; // Import the styles
import PrivacyPolicyPdf from '../../pages/PantryPalPrivacyPolicy.pdf';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <p>&copy; 2024 QT Digital. All rights reserved.</p>
            <nav>
            <a href="/contact">Contact</a>
            <a href={PrivacyPolicyPdf} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </nav>
      </footer>
    );
};

export default Footer;
import React from 'react';
import './Home.css'; // We'll create this file for styling
import Hero from '../components/Hero/Hero.jsx';
import RecipeHero from '../components/RecipeHero/RecipeHero.jsx';
import Footer from '../components/Footer/Footer.jsx';

function Home() {
  return (
    <div className="Home">
      <Hero />
      <RecipeHero />
      <Footer />
    </div>
  );
}

export default Home;
import React from 'react';
import './HeroButton.css';

const HeroButton = ({ size = 'large' }) => {
  const handleClick = () => {
    window.open("https://apps.apple.com/au/app/pantrypal-meals-you-can-cook/id6447123179", '_blank');
  };

  const buttonClass = `hero-button-${size}`;
  const textClass = `button-text-${size}`;

  return (
    <button className={buttonClass} onClick={handleClick}>
      <span className={textClass}>Download PantryPal</span>
    </button>
  );
};

export default HeroButton;

import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Logo from '../../static/PieGuy120.png';
import HeroButton from '../HeroButton/HeroButton';

const Navbar = () => (
  <div className="navbar">
    <Link to="/" style={{textDecoration: 'none'}}>
      <div className="logo-container">
        <img 
          className="logo"
          src={Logo}
          alt="Logo"
        />
        <div className="app-name" style={{textDecoration: 'none'}}>PantryPal</div>
      </div>
    </Link>
    <div className="nav-links">
      <Link to="/" className="nav-item">Home</Link>
      <Link to="/contact" className="nav-item">Contact</Link>
    </div>
    <div className='hero-button'>
      <HeroButton size="small"/>
    </div>
  </div>
);

export default Navbar;
import React from 'react';
import Icon from '../Icon/Icon';
import styles from './Recipe.module.css';

const Recipe = ({ imageUrl, recipeName, author, ingredientAmount }) => {
    return (
        <div className={styles.recepie}>
            <div 
                className={styles.image} 
                style={{ backgroundImage: `url(${imageUrl})` }}
            >
                <div className={styles.timeIndicator}>
                    <Icon name={'FridgeIcon'} fill={'#6A4B34'} />
                    <b className={styles.b}>{ingredientAmount}</b>
                </div>
            </div>
            <div className={styles.bottom}>
                <b className={styles.recipesName}>{recipeName}</b>
                <b className={styles.recipeTinEats}>{author}</b>
            </div>
        </div>
    );
};

export default Recipe;
